@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');

* {
    box-sizing: border-box;
}

html, body {
    font-size:0;
    font-family:Montserrat;
}

header {
    display:block;
    padding:50px 20px;
    text-align:center;
    width: 100%;
    img {
        display:inline-block;
        max-width:100%;
    }
    h1 {
        font-size: 40px;
        font-weight: 600;
        span {
            color:$color-secundario;
        }
    }
}

.cuerpo {
    display:block;
    width:100%;
}

.content {
    display:block;
    margin:auto;
    max-width: 1366px;    
    padding:20px;
    width: 100%;
}

p {
    font-size:15px;  
}

.black {
    background-color: #000;
    display:block;
    position: relative;
    width: 100%;
    p {
        color:#FFF;      
        font-size:20px;  
    }
    .st-v {
        color:$color-secundario;
        font-weight: 600;
        text-align: center;
    }
    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: -40px;
        width: 100%;
        height: 200px;
        z-index: -1;
        transform: skewY(-2deg);
        background: #000;
        background-size: cover;
        border-bottom: .2em solid #fff;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: -40px;
        width: 100%;
        height: 200px;
        z-index: -1;
        transform: skewY(-2deg);
        background: #000;
        background-size: cover;
        border-bottom: .2em solid #fff;
    }
}

.txt {
    padding: 100px;
    p {
        font-size:20px;
    }
}

.izq, .der {
    display:inline-block;
    font-size:22px;
    margin-bottom:50px;
    vertical-align: middle;
    width:50%;
    img {
        max-width: 100%;;
    }
    h2 {
        color:$color-secundario;
        font-size:30px;
    }
}

.izq {
    text-align:right;
    padding-right:20px;
}
.der {
    padding-left:20px;
}

.pie {
    text-align:center;
    font-size:17px;
    font-weight: 600;
    padding:50px 20px;
    a {
        color:$color-secundario;
    }
}