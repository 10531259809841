@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');

//LOGIN
$color-principal:#000;
$color-secundario:#00dc5d;
$urlBase: "https://www.cacerplastic.con";

@mixin opacity($opacidad) {
    opacity:$opacidad;
    filter:alpha(opacity=$opacidad*100);
}

@mixin filter($filtro) {    
    -webkit-filter: $filtro;
    filter: $filtro;
}

@mixin border-radius($radius) {
            border-radius: $radius;
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
}

@mixin box-shadow($sombra) {
            box-shadow: $sombra;
    -webkit-box-shadow: $sombra;
       -moz-box-shadow: $sombra;
}